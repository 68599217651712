import styled from "styled-components";
import border from "@src/presentation/design-tokens/border";
import color from "@src/presentation/design-tokens/color";
import fontWeight from "@src/presentation/design-tokens/font-weight";
import padding from "@src/presentation/design-tokens/padding";

export const Wrapper = styled.div`
  background-color: ${color.primary.medium};
  border-radius: ${border.radius.none};
  color: ${color.neutral.light};
  font-size: 1.25rem;
  font-weight: ${fontWeight.medium};
  padding: ${padding.p4} ${padding.p6};
  text-align: center;
`;

export const CaptionText = styled.div``;
