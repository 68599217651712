import { ReactElement } from "react";
import { Container } from "react-bootstrap";
import Header from "@src/presentation/components/shared/header";
import Footer from "@src/presentation/components/shared/footer";
import GloablStyleWrapper from "@src/presentation/components/shared/global-style-wrapper";
import * as Styles from "./styles";

interface Props {
  children: ReactElement;
}

const PageLayout = ({ children }: Props): JSX.Element => {
  return (
    <GloablStyleWrapper>
      <Styles.Wrapper>
        <Header />
        <Container fluid>{children}</Container>
        <Footer />
      </Styles.Wrapper>
    </GloablStyleWrapper>
  );
};

export default PageLayout;
