import styled from "styled-components";

export const $layoutPaddingBottom = "16rem";

export const $layoutPaddingTop = "4.625rem";

export const $contentMinHeight = `calc(100vh - ${$layoutPaddingBottom} - ${$layoutPaddingTop})`;

export const Wrapper = styled.div`
  min-height: 100vh;
  padding: ${$layoutPaddingTop} 0 ${$layoutPaddingBottom} 0;
  position: relative;
`;
