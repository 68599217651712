import styled from "styled-components";
import fontWeight from "@src/presentation/design-tokens/font-weight";
import margin from "@src/presentation/design-tokens/margin";
import padding from "@src/presentation/design-tokens/padding";

export const CopyButton = styled.div`
  display: inline-block;
  margin-right: ${margin.m2};
`;

export const EthPrice = styled.div`
  font-weight: ${fontWeight.bold};
  margin-bottom: ${margin.m3};
  margin-top: ${margin.m3};
`;

export const EtherscanLink = styled.div`
  word-break: break-all;
`;

export const SoldOutMessage = styled.div`
  color: red;
`;

export const TitleWrapper = styled.div`
  display: -webkit-box;
  line-clamp: 2;
  min-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  .card-title {
    margin: 0;
  }
`;

export const Wrapper = styled.div`
  cursor: pointer;
  margin-bottom: 2rem;

  .card {
    .card-body {
      padding-top: ${padding.p2};

      .btn {
        width: 100%;
      }
    }
  }

  p {
    margin-bottom: ${margin.m4};
  }
`;
