import { useContext } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import { BloctoWalletContext } from "@src/core/contexts/blocto-wallet";
import { MetaMaskWalletContext } from "@src/core/contexts/meta-mask-wallet";
import WalletDetails from "@src/presentation/components/wallet/details";
import WalletOpenModalButton from "@src/presentation/components/wallet/open-modal-button";
import * as Styles from "./styles";

const Header = (): JSX.Element => {
  const { metaMaskWallet } = useContext(MetaMaskWalletContext);

  return (
    <Styles.Wrapper>
      <Navbar expand="sm" fixed="top">
        <Navbar.Toggle aria-controls="navbar-options">
          <FaBars />
        </Navbar.Toggle>
        <Navbar.Collapse
          className="justify-content-between"
          id="navbar-options"
        >
          <Styles.ActionLink>
            <Button href="/">&#8592; Go Back</Button>
          </Styles.ActionLink>
          <Nav>
            <Styles.WalletContentWrapper>
              {!metaMaskWallet && <WalletOpenModalButton />}
              {metaMaskWallet && <WalletDetails />}
            </Styles.WalletContentWrapper>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Styles.Wrapper>
  );
};

export default Header;
