import { FaDiscord, FaInstagram, FaTiktok, FaTwitter } from "react-icons/fa";
import logo from "@src/presentation/assets/images/blockparty-logo-light.svg";
import * as Styles from "./styles";

const Footer = (): JSX.Element => {
  return (
    <Styles.Wrapper>
      <Styles.ContentWrapper>
        <Styles.LogoWrapper>
          <Styles.Logo src={logo} />
        </Styles.LogoWrapper>
        <Styles.LinksWrapper>
          <Styles.Link href="https://blockparty.co/terms" target="_blank">
            Terms of Service
          </Styles.Link>
          <Styles.Link
            href="https://blockparty.co/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Styles.Link>
        </Styles.LinksWrapper>
      </Styles.ContentWrapper>
      <Styles.SocialMedia>
        <Styles.SocialMediaLink
          target="_blank"
          href="https://www.instagram.com/goblockparty/"
        >
          <FaInstagram />
        </Styles.SocialMediaLink>
        <Styles.SocialMediaLink
          target="_blank"
          href="https://www.tiktok.com/@blockparty.co"
        >
          <FaTiktok />
        </Styles.SocialMediaLink>
        <Styles.SocialMediaLink
          target="_blank"
          href="https://twitter.com/blockparty"
        >
          <FaTwitter />
        </Styles.SocialMediaLink>
        <Styles.SocialMediaLink
          target="_blank"
          href="https://discord.com/invite/qp9DqgrMCu"
        >
          <FaDiscord />
        </Styles.SocialMediaLink>
      </Styles.SocialMedia>
    </Styles.Wrapper>
  );
};

export default Footer;
