import {
  createContext,
  useState,
  Dispatch,
  ReactElement,
  useEffect,
} from "react";
import { MaticRate } from "@src/core/models/matic-rate";

export interface MaticRateContextProps {
  maticRate?: MaticRate;
  setMaticRate?: Dispatch<MaticRate>;
}

interface MaticRateProviderProps {
  children: ReactElement;
}

export const MaticRateContext = createContext<MaticRateContextProps>({});

export const MaticRateProvider = ({
  children,
}: MaticRateProviderProps): JSX.Element => {
  const [maticRate, setMaticRate] = useState<MaticRate>();

  useEffect(() => findCurrentRate(setMaticRate), []);

  return (
    <MaticRateContext.Provider value={{ maticRate, setMaticRate }}>
      {children}
    </MaticRateContext.Provider>
  );
};

const findCurrentRate = (setMaticRate: Dispatch<MaticRate>): void => {
  fetch(
    "https://api.coingecko.com/api/v3/simple/price?ids=wmatic&vs_currencies=usd"
  )
    .then((response) => response.json())
    .then((json) => {
      const maticPrice: string = json?.wmatic?.usd;

      if (!maticPrice) return;

      setMaticRate({ currentRate: Number(maticPrice) });
    })
    .catch((error) => console.error(error));
};
