import { rgba } from "polished";
import styled from "styled-components";
import color from "@src/presentation/design-tokens/color";
import display from "@src/presentation/design-tokens/display";
import fontWeight from "@src/presentation/design-tokens/font-weight";
import margin from "@src/presentation/design-tokens/margin";
import padding from "@src/presentation/design-tokens/padding";

export const ActionLink = styled.div`
  color: ${color.neutral.light};
  cursor: pointer;
  font-weight: ${fontWeight.semibold};
  text-transform: uppercase;

  &:hover {
    color: ${color.neutral.medium};
  }
`;

export const Logo = styled.img`
  height: 4rem;
`;

export const WalletContentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: end;

  @media (max-width: ${display.sm}) {
    margin-bottom: ${margin.m4};
    margin-top: ${margin.m4};
  }
`;

export const Wrapper = styled.div`
  .btn {
    font-size: 1rem;
    padding: ${padding.p2} ${padding.p4};
    text-transform: uppercase;
  }

  .navbar {
    background-color: ${color.primary.medium};
    border-bottom: 1px solid ${rgba(color.neutral.medium, 0.1)};
    padding: 1rem 6rem;

    @media (max-width: ${display.md}) {
      padding: 1rem 2rem;
    }
  }

  .navbar-nav {
    align-items: center;

    a {
      font-size: 1rem;
      line-height: 1;
      text-decoration: none;
    }

    ${ActionLink} {
      line-height: 1;
      margin-right: ${margin.m4};
    }

    @media (max-width: ${display.sm}) {
      margin-top: ${margin.m3};
    }
  }

  .navbar-toggler {
    color: ${color.neutral.light};
  }
`;
