import { Base } from "@src/core/models/base";

export interface Offer extends Base {
  assetIds: string[];
  description: string;
  dropId: string;
  editionsCount: number;
  kind?: OfferKind;
  name: string;
  mediaUrl: string;
  previewUrlLarge: string;
  previewUrlSmall: string;
  availableCount: string;
  status?: OfferStatus;
  price: number;
}

export enum OfferKind {
  Art = "art",
  Audio = "audio",
  Video = "video",
}

export enum OfferStatus {
  Available = "available",
  Closed = "closed",
  SoldOut = "soldOut",
}
