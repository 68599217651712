import styled from "styled-components";
import margin from "@src/presentation/design-tokens/margin";
import { $contentMinHeight } from "@src/presentation/components/shared/page-layout/styles";

export const ButtonWrapper = styled.div`
  margin: ${margin.m3} 0;
`;

export const Image = styled.img``;

export const Text = styled.div``;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  min-height: ${$contentMinHeight};
  text-align: center;
`;
