import styled from "styled-components";
import color from "@src/presentation/design-tokens/color";
import display from "@src/presentation/design-tokens/display";
import fontWeight from "@src/presentation/design-tokens/font-weight";
import margin from "@src/presentation/design-tokens/margin";

export const Bio = styled.p`
  margin-bottom: ${margin.m5};
`;

export const ContentWrapper = styled.div`
  margin-bottom: ${margin.m6};
  margin-left: ${margin.m5};

  @media (max-width: ${display.lg}) {
    margin-left: ${margin.m0};
  }
`;

export const SocialMediaButton = styled.a`
  color: ${color.primary.light};
  margin: 0 ${margin.m2};
`;

export const SocialMediaLabel = styled.span`
  font-size: 1.25rem;
  font-weight: ${fontWeight.medium};
  margin-right: ${margin.m2};
`;

export const SocialMediaWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div``;
