import { useContext } from "react";
import { DropContext } from "@src/core/contexts/drop";
import { Offer } from "@src/core/models/offer";
import OfferArrivingSoon from "./arriving-soon";
import OfferBuyNow from "./buy-now";
import OfferExpiredDrop from "./expired-drop";
import OfferSoldOut from "./sold-out";
import * as Styles from "./styles";

interface Props {
  offer: Offer;
}

const OfferCallToAction = ({ offer }: Props): JSX.Element => {
  const isSoldOut = Number(offer.availableCount) === 0;
  const { dropIsActive, dropIsExpired } = useContext(DropContext);

  return (
    <Styles.Wrapper>
      {dropIsActive && !dropIsExpired && !isSoldOut && (
        <OfferBuyNow offer={offer} />
      )}
      {!dropIsActive && !dropIsExpired && !isSoldOut && <OfferArrivingSoon />}
      {isSoldOut && <OfferSoldOut />}
      {dropIsExpired && !isSoldOut && <OfferExpiredDrop />}
    </Styles.Wrapper>
  );
};

export default OfferCallToAction;
