import { Button, Card, Col, Form, Row } from "react-bootstrap";
import TitleSubSection from "@src/presentation/components/shared/title/sub-section";

const OfferArrivingSoon = (): JSX.Element => {
  return (
    <Card>
      <Card.Body>
        <TitleSubSection text="Arriving soon" />
      </Card.Body>
    </Card>
  );
};

export default OfferArrivingSoon;
