import moment from "moment";
import {
  createContext,
  useState,
  Dispatch,
  ReactElement,
  useEffect,
} from "react";
import { Drop } from "@src/core/models/drop";
import endpoints from "@src/core/api/endpoints";
import { HttpClient } from "@src/core/api/http-client";

export interface DropContextProps {
  drop?: Drop;
  dropIsActive?: boolean;
  dropIsExpired?: boolean;
}

interface DropProviderProps {
  children: ReactElement;
}

export const DropContext = createContext<DropContextProps>({});

export const DropProvider = ({ children }: DropProviderProps): JSX.Element => {
  const [drop, setDrop] = useState<Drop>();
  const [dropIsActive, setDropIsActive] = useState<boolean>();
  const [dropIsExpired, setDropIsExpired] = useState<boolean>();
  const oneSecond = 1000;

  useEffect(() => {
    findDrop(setDrop, setDropIsActive, setDropIsExpired);
  }, [setDrop, setDropIsActive, setDropIsExpired]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!drop) return;

      const hasExpired = drop.endsAt && moment(drop.endsAt).isBefore();
      const hasStarted = moment(drop.startsAt).isBefore();

      if (hasExpired) setDropIsExpired(true);

      if (hasStarted) setDropIsActive(true);
    }, oneSecond);

    return () => clearInterval(interval);
  }, [drop]);

  return (
    <DropContext.Provider
      value={{
        drop,
        dropIsActive,
        dropIsExpired,
      }}
    >
      {children}
    </DropContext.Provider>
  );
};

const findDrop = (
  setDrop: Dispatch<Drop>,
  setDropIsActive: Dispatch<boolean>,
  setDropIsExpired: Dispatch<boolean>
): void => {
  HttpClient.list<Drop>(endpoints.dropsList(), (response) => {
    const drop = response[0];

    setDrop(drop);
    setDropIsExpired(false);
    setDropIsActive(false);
  });
};
