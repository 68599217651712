import MessageTemplate from "@src/presentation/components/shared/message-template";
import { FaRegCopy } from "react-icons/fa";
import Web3 from "web3";
import * as Styles from "./styles";

interface Props {
  transactionHash: string;
}
const CheckoutSuccess = ({ transactionHash }: Props): JSX.Element => {
  const copyEtherscanAddress = (event: any): void => {
    event.stopPropagation();

    navigator.clipboard.writeText(formatEtherscanAddress());
  };

  const formatEtherscanAddress = (): string => {
    return `${process.env.REACT_APP_ETHERSCAN_URL}/tx/${transactionHash}`;
  };

  return (
    <Styles.Wrapper>
      <MessageTemplate
        image=""
        primaryActionText="Go to Homepage"
        primaryActionUrl="/"
        buttonUnderscript=""
        text="Once the transaction is complete, your NFT will be available in your wallet. This operation may take several minutes. Please track and verify your purchase confirmation directly on Polygonscan via the link below. We appreciate your visit and hope to see you again in the future!"
        title="Your purchase is being processed"
      >
        <>
          <Styles.EtherscanLink>
            <p>View your transaction on Polygonscan:</p>
            <Styles.CopyButton
              onClick={(event: any) => copyEtherscanAddress(event)}
            >
              <FaRegCopy />
            </Styles.CopyButton>
            <a target="_blank" href={formatEtherscanAddress()} rel="noreferrer">
              {formatEtherscanAddress()}
            </a>
          </Styles.EtherscanLink>
          <p>
            If you want a specific NFT edition number (i.e. #420) you can make
            and browse offers on the&nbsp;
            <a href="https://app.nft.org" target="_blank" rel="noreferrer">
              NFT Protocol DEX.
            </a>
          </p>
        </>
      </MessageTemplate>
    </Styles.Wrapper>
  );
};

export default CheckoutSuccess;
