import { useContext } from "react";
import { Button } from "react-bootstrap";
import { DropContext } from "@src/core/contexts/drop";
import { Offer } from "@src/core/models/offer";
import * as Styles from "./styles";

interface Props {
  offer: Offer;
}

const OfferActionButton = ({ offer }: Props): JSX.Element => {
  const { dropIsActive, dropIsExpired } = useContext(DropContext);

  return (
    <Styles.Wrapper>
      <Button variant="primary">
        {defineButtonMessage(offer, dropIsActive, dropIsExpired)}
      </Button>
    </Styles.Wrapper>
  );
};

const defineButtonMessage = (
  offer: Offer,
  dropIsActive?: boolean,
  dropIsExpired?: boolean
): string => {
  if (isSoldOut(offer)) return "Sold out";

  if (dropIsExpired) return "See details";

  if (!dropIsActive) return "See details";

  return "Buy now";
};

const isSoldOut = (offer: Offer): boolean => {
  return !Number(offer.availableCount);
};

export default OfferActionButton;
