import { promptMetaMaskWalletConnection } from "@src/core/contexts/meta-mask-wallet";
import logo from "@src/presentation/assets/images/meta-mask-logo.svg";
import WalletProviderButton from "..";

const WalletMetaMaskButton = (): JSX.Element => {
  const description =
    "Connect Your Wallet to Start Collect Buying and Selling NFTs.";
  const walletName = "Meta Mask";

  return (
    <WalletProviderButton
      description={description}
      image={logo}
      onClick={() => promptMetaMaskWalletConnection()}
      walletName={walletName}
    />
  );
};

export default WalletMetaMaskButton;
