import faker from "@faker-js/faker";
import { Offer, OfferKind, OfferStatus } from "@src/core/models/offer";
import { getRandom } from "@src/core/support/array";

export const mockOffer = (overrides?: Offer): Offer => ({
  assetIds: ["d3f14330-93dc-420e-a156-6a41d6422ae9"],
  createdAt: faker.date.past(),
  createdBy: faker.datatype.uuid(),
  description: faker.lorem.paragraph(),
  dropId: faker.datatype.uuid(),
  editionsCount: faker.datatype.number({ min: 5, max: 10 }),
  kind: OfferKind.Art,
  id: faker.datatype.uuid(),
  name: faker.lorem.sentence(),
  mediaUrl: faker.image.lorempicsum.image(
    getRandom([480, 640, 1024]),
    getRandom([480, 640, 1024])
  ),
  previewUrlLarge: faker.image.lorempicsum.image(
    getRandom([480, 640, 1024]),
    getRandom([480, 640, 1024])
  ),
  previewUrlSmall: faker.image.lorempicsum.image(
    getRandom([480, 640, 1024]),
    getRandom([480, 640, 1024])
  ),
  availableCount: faker.datatype.number({ min: 0, max: 5 }).toString(),
  status: OfferStatus.Available,
  updatedAt: faker.date.past(),
  updatedBy: faker.datatype.uuid(),
  price: Number(0.01),
  ...(overrides || {}),
});
