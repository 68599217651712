import { Col, Container, Row } from "react-bootstrap";
import { Creator } from "@src/core/models/creator";
import { Drop } from "@src/core/models/drop";
import { Offer } from "@src/core/models/offer";
import { formatMatic } from "@src/core/support/format-currency";
import DropCountdown from "@src/presentation/components/drops/countdown";
import OfferContentPreview from "@src/presentation/components/offers/content-preview";
import OfferCallToAction from "./call-to-action";
import * as Styles from "./styles";

interface Props {
  creator: Creator;
  drop?: Drop;
  dropIsActive?: boolean;
  dropIsExpired?: boolean;
  offer: Offer;
}

const OfferDetails = ({
  creator,
  drop,
  dropIsActive,
  dropIsExpired,
  offer,
}: Props): JSX.Element => {
  return (
    <Styles.Wrapper>
      <Container>
        <Row>
          <Col sm={{ span: 6, offset: 3 }}>
            {drop && (
              <DropCountdown
                drop={drop}
                dropIsActive={dropIsActive}
                dropIsExpired={dropIsExpired}
              />
            )}
            <OfferContentPreview offer={offer} />
          </Col>
          <Col sm={{ span: 6, offset: 3 }}>
            <Styles.ContentWrapper>
              <Styles.Title>{offer.name}</Styles.Title>
              <p>{offer.description}</p>
              {renderTags(offer, creator)}
              {renderCallToAction(offer)}
            </Styles.ContentWrapper>
          </Col>
        </Row>
      </Container>
    </Styles.Wrapper>
  );
};

OfferDetails.defaultProps = {
  drop: undefined,
  dropIsActive: undefined,
  dropIsExpired: undefined,
};

const renderCallToAction = (offer: Offer): JSX.Element => {
  return (
    <Styles.CallToActionWrapper>
      <OfferCallToAction offer={offer} />
    </Styles.CallToActionWrapper>
  );
};

const renderTags = (offer: Offer, creator: Creator): JSX.Element => {
  return (
    <Styles.TagsWrapper>
      <Styles.CreatorTag>
        <Styles.CreatorImage roundedCircle src={creator.profileImageUrl} />
        <div>
          <Styles.TagLabel>Creator</Styles.TagLabel>
          <Styles.TagText>{creator.name}</Styles.TagText>
        </div>
      </Styles.CreatorTag>
      <Styles.PriceTag>
        <Styles.TagLabel>Price</Styles.TagLabel>
        <Styles.TagText>{formatMatic(offer.price)}</Styles.TagText>
      </Styles.PriceTag>
    </Styles.TagsWrapper>
  );
};

export default OfferDetails;
