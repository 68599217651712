import styled from "styled-components";
import color from "@src/presentation/design-tokens/color";
import fontWeight from "@src/presentation/design-tokens/font-weight";
import margin from "@src/presentation/design-tokens/margin";

export const List = styled.div`
  margin-top: ${margin.m4};
`;

export const ListItem = styled.div`
  display: flex;
  margin-bottom: ${margin.m2};

  & > div:nth-child(1) {
    color: ${color.primary.light};
    font-weight: ${fontWeight.medium};
    width: 7rem;
  }

  & > div:nth-child(2) {
    font-size: 1.1rem;
    font-weight: ${fontWeight.medium};
    margin-left: ${margin.m4};
  }
`;

export const Wrapper = styled.div``;
