import styled from "styled-components";
import margin from "@src/presentation/design-tokens/margin";

export const ErrorMessageWrapper = styled.div`
  font-weight: bold;
  text-align: center;
  color: red;
`;

export const Icon = styled.div`
  font-size: 1.2rem;
  margin-right: ${margin.m3};
`;

export const Logo = styled.img`
  height: 1.2rem;
  margin-right: ${margin.m3};
`;

export const Overlay = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1040;
`;

export const HighlightWrapper = styled.div`
  background-color: #e6e6e6;
  border: solid 1px gray;
  padding: 10px 20px 0px 20px;
`;

export const OverlayLoadingMessage = styled.div``;

export const OverlayLoadingWrapper = styled.div`
  align-items: center;
  background-color: white;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 4rem;

  .spinner-grow {
    margin-bottom: 2rem;
  }
`;
