import { Button, Card, Col, Form, Row } from "react-bootstrap";
import TitleSubSection from "@src/presentation/components/shared/title/sub-section";

const OfferExpiredDrop = (): JSX.Element => {
  return (
    <Card>
      <Card.Body>
        <TitleSubSection text="Drop has expired" />
        {renderNotificationForm()}
      </Card.Body>
    </Card>
  );
};

const renderNotificationForm = (): JSX.Element => {
  return (
    <Form>
      <Row className="align-items-end">
        <Col xs={12}>
          <Form.Group>
            <Form.Label>Get notifications about new drops</Form.Label>
            <Form.Control type="email" placeholder="Enter your e-mail" />
          </Form.Group>
        </Col>
        <Col xs={12} className="mt-4 mt-xl-0">
          <Form.Group>
            <Button type="submit">Subscribe</Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default OfferExpiredDrop;
