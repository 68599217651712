import styled from "styled-components";
import border from "@src/presentation/design-tokens/border";
import color from "@src/presentation/design-tokens/color";
import fontWeight from "@src/presentation/design-tokens/font-weight";
import margin from "@src/presentation/design-tokens/margin";

interface WrapperProps {
  alignment?: string;
}

export const Decoration = styled.div`
  align-items: center;
  display: flex;

  &:after {
    margin-left: ${margin.m1};
    width: 0.5rem;
  }

  &:before {
    margin-left: ${margin.m3};
    width: 1.4rem;
  }

  &:after,
  &:before {
    border-bottom: 0.2rem solid ${color.primary.medium};
    border-radius: ${border.radius.none};
    content: " ";
    display: inline-block;
  }
`;

export const Title = styled.span``;

export const TitleSubsection = styled.div<WrapperProps>`
  align-items: ${(props) => props.alignment};
  display: flex;
  font-size: 1.4rem;
  font-weight: ${fontWeight.semibold};
  text-align: ${(props) => props.alignment};
  text-transform: uppercase;
`;
