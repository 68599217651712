/* eslint-disable jsx-a11y/media-has-caption */
import { Offer } from "@src/core/models/offer";
import * as Styles from "./styles";

interface Props {
  controls?: boolean;
  muted?: boolean;
  offer: Offer;
  previewSize?: "large" | "small";
}

const OfferThumbnail = ({
  controls,
  muted,
  offer,
  previewSize,
}: Props): JSX.Element => {
  const assetUrl =
    previewSize === "large" ? offer.previewUrlLarge : offer.previewUrlSmall;

  return (
    <Styles.OfferThumbnail>
      <video controls={controls} autoPlay muted={muted}>
        <source src={assetUrl} type="video/mp4" />
      </video>
    </Styles.OfferThumbnail>
  );
};

OfferThumbnail.defaultProps = {
  controls: true,
  muted: true,
  previewSize: "small",
};

export default OfferThumbnail;
