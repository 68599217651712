import { Dispatch, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import endpoints from "@src/core/api/endpoints";
import { HttpClient } from "@src/core/api/http-client";
import { BloctoWalletContext } from "@src/core/contexts/blocto-wallet";
import { MetaMaskWalletContext } from "@src/core/contexts/meta-mask-wallet";
import { Order } from "@src/core/models/order";
import { Wallet } from "@src/core/models/wallet";
import CheckoutMoonpay from "@src/presentation/components/checkout/moonpay";
import PageLayout from "@src/presentation/components/shared/page-layout";

interface CheckoutMoonpayPageParams {
  orderId: string;
}

const CheckoutMoonpayPage = (): JSX.Element => {
  const { bloctoWallet } = useContext(BloctoWalletContext);
  const { metaMaskWallet } = useContext(MetaMaskWalletContext);
  const [order, setOrder] = useState<Order>();
  const { orderId } = useParams<CheckoutMoonpayPageParams>();

  useEffect(() => findOrder(orderId, setOrder), [orderId]);

  return (
    <PageLayout>
      <>
        {bloctoWallet && order && renderMoonpayCheckout(bloctoWallet, order)}
        {metaMaskWallet &&
          order &&
          renderMoonpayCheckout(metaMaskWallet, order)}
      </>
    </PageLayout>
  );
};

const renderMoonpayCheckout = (wallet: Wallet, order: Order): JSX.Element => {
  return <CheckoutMoonpay wallet={wallet} order={order} />;
};

const findOrder = (orderId: string, setOrder: Dispatch<Order>): void => {
  HttpClient.get<Order>(endpoints.ordersFind(orderId), (response) => {
    setOrder(response);
  });
};

export default CheckoutMoonpayPage;
