import styled from "styled-components";
import color from "@src/presentation/design-tokens/color";
import margin from "@src/presentation/design-tokens/margin";

export const Line = styled.div`
  border-bottom: 1px solid ${color.neutral.light};
  height: 1px;
  margin: ${margin.m5} 0;
  width: 100%;
`;

export const Wrapper = styled.div``;
