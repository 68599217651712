import { promptBloctoWalletConnection } from "@src/core/contexts/blocto-wallet";
import logo from "@src/presentation/assets/images/blocto-logo.png";
import WalletProviderButton from "..";
import * as Styles from "./styles";

const WalletBloctoButton = (): JSX.Element => {
  const description =
    "Connect Your Wallet to Start Collect Buying and Selling NFTs.";
  const walletName = "Blocto";

  return (
    <>
      <Styles.BloctoIframeGlobalStyle />
      <WalletProviderButton
        description={description}
        image={logo}
        onClick={() => promptBloctoWalletConnection()}
        walletName={walletName}
      />
    </>
  );
};

export default WalletBloctoButton;
