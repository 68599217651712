import * as fcl from "@onflow/fcl";
import {
  createContext,
  useState,
  Dispatch,
  ReactElement,
  useEffect,
} from "react";
import { Wallet, WalletType } from "@src/core/models/wallet";

export interface BloctoWalletContextProps {
  bloctoWallet?: Wallet;
  setBloctoWallet?: Dispatch<Wallet | undefined>;
}

interface BloctoWalletProviderProps {
  children: ReactElement;
}

export const BloctoWalletContext = createContext<BloctoWalletContextProps>({});

export const BloctoWalletProvider = ({
  children,
}: BloctoWalletProviderProps): JSX.Element => {
  const [wallet, setWallet] = useState<Wallet>();

  useEffect(() => {
    signWalletEvents();
  }, []);

  useEffect(() => {
    signUserEvents(setWallet);
  }, [setWallet]);

  return (
    <BloctoWalletContext.Provider
      value={{ bloctoWallet: wallet, setBloctoWallet: setWallet }}
    >
      {children}
    </BloctoWalletContext.Provider>
  );
};

export const promptBloctoWalletConnection = (): void => {
  fcl.authenticate();
};

export const signWalletEvents = (): void => {
  fcl
    .config()
    .put("accessNode.api", "https://access-testnet.onflow.org")
    .put("challenge.handshake", "https://flow-wallet-testnet.blocto.app/authn");
};

export const signUserEvents = (
  callback: (wallet: Wallet | undefined) => void
): void => {
  fcl.currentUser().subscribe((user: any) => {
    if (user.loggedIn) {
      callback({ balance: 0, id: user.addr, type: WalletType.Blocto });
    }
  });
};

export const disconnectBloctoWallet = (
  callback: (wallet: Wallet | undefined) => void
): void => {
  fcl.unauthenticate();
  callback(undefined);
};
