import styled from "styled-components";
import color from "@src/presentation/design-tokens/color";
import { OfferThumbnail } from "@src/presentation/components/offers/thumbnail/styles";

export const ImageOverlay = styled.div`
  align-items: center;
  background-color: #000;
  color: ${color.neutral.light};
  cursor: pointer;
  display: flex;
  font-size: 3rem;
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 1000;
  animation: fade 1s linear;
  transition: opacity 0.3s linear;
`;

export const OfferContentPreview = styled.div`
  position: relative;

  ${OfferThumbnail} {
    aspect-ratio: auto;
    align-items: start;
    margin: 0;
  }

  &:hover {
    ${ImageOverlay} {
      transition: opacity 0.3s linear;
      opacity: 0.8;
    }
  }
`;
