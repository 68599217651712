import { Dispatch, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import endpoints from "@src/core/api/endpoints";
import { HttpClient } from "@src/core/api/http-client";
import { Order } from "@src/core/models/order";
import TitleSection from "@src/presentation/components/shared/title/section";
import PageLayout from "@src/presentation/components/shared/page-layout";
import { Offer } from "@/src/core/models/offer";
import { Asset } from "@/src/core/models/asset";
import * as Styles from "./styles";

interface CheckoutNewPageParams {
  orderId: string;
}

const CheckoutNewPage = (): JSX.Element => {
  const [order, setOrder] = useState<Order>();
  const [offer, setOffer] = useState<Offer>();
  const [asset, setAsset] = useState<Asset>();
  const { orderId } = useParams<CheckoutNewPageParams>();

  useEffect(() => findOrder(orderId, setOrder), [orderId]);
  useEffect(() => order && findOffer(order, setOffer), [order]);

  return (
    <PageLayout>
      <Styles.Wrapper>
        <Styles.SectionTitleWrapper>
          <TitleSection text="Confirm your order" />
        </Styles.SectionTitleWrapper>
      </Styles.Wrapper>
    </PageLayout>
  );
};

const findOrder = (id: string, setOrder: Dispatch<Order>): void => {
  HttpClient.get<Order>(endpoints.ordersFind(id), (response) => {
    setOrder(response);
  });
};

const findOffer = (order: Order, setOffer: Dispatch<Offer>): void => {
  HttpClient.get<Offer>(endpoints.offersFind(order.offerId), (response) => {
    setOffer(response);
  });
};

export default CheckoutNewPage;
