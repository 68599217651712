import { Button } from "react-bootstrap";
import TitleSection from "@src/presentation/components/shared/title/section";
import { ReactElement } from "react";
import * as Styles from "./styles";

interface Props {
  children?: ReactElement;
  image: string;
  primaryActionText?: string;
  primaryActionUrl?: string;
  secondaryActionText?: string;
  secondaryActionUrl?: string;
  title: string;
  text: string;
  buttonUnderscript: string;
}

const MessageTemplate = ({
  children,
  image,
  primaryActionText,
  primaryActionUrl,
  secondaryActionText,
  secondaryActionUrl,
  title,
  text,
  buttonUnderscript,
}: Props): JSX.Element => {
  return (
    <Styles.Wrapper>
      <Styles.Image src={image} />
      <TitleSection text={title} />
      <Styles.Text>
        <p>{text}</p>
      </Styles.Text>
      {children}
      <Styles.ButtonWrapper>
        {primaryActionText && primaryActionUrl && (
          <Button target="_blank" href={primaryActionUrl}>
            {primaryActionText}
          </Button>
        )}
      </Styles.ButtonWrapper>
      {secondaryActionText && secondaryActionUrl && (
        <>
          <p>or</p>
          <a target="_blank" href={secondaryActionUrl} rel="noreferrer">
            {secondaryActionText}
          </a>
        </>
      )}
    </Styles.Wrapper>
  );
};

MessageTemplate.defaultProps = {
  children: undefined,
  primaryActionText: undefined,
  primaryActionUrl: undefined,
  secondaryActionText: undefined,
  secondaryActionUrl: undefined,
};

export default MessageTemplate;
