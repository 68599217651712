import { Dispatch, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import endpoints from "@src/core/api/endpoints";
import { HttpClient } from "@src/core/api/http-client";
import { CreatorContext } from "@src/core/contexts/creator";
import { DropContext } from "@src/core/contexts/drop";
import { Offer } from "@src/core/models/offer";
import OfferDetails from "@src/presentation/components/offers/details";
import PageLayout from "@src/presentation/components/shared/page-layout";
import * as Styles from "./styles";

interface OfferShowPageParams {
  id: string;
}

const OfferShowPage = (): JSX.Element => {
  const { id } = useParams<OfferShowPageParams>();
  const [offer, setOffer] = useState<Offer>();
  const { drop, dropIsActive, dropIsExpired } = useContext(DropContext);
  const { creator } = useContext(CreatorContext);

  useEffect(() => findOffer(id, setOffer), [id]);

  return (
    <PageLayout>
      <Styles.Wrapper>
        {offer && creator && (
          <OfferDetails
            creator={creator}
            drop={drop}
            dropIsActive={dropIsActive}
            dropIsExpired={dropIsExpired}
            offer={offer}
          />
        )}
      </Styles.Wrapper>
    </PageLayout>
  );
};

const findOffer = (id: string, setOffer: Dispatch<Offer>): void => {
  HttpClient.get<Offer>(endpoints.offersFind(id), (response) => {
    setOffer(response);
  }).catch((e) => console.log(e));
};

export default OfferShowPage;
