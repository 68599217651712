import styled from "styled-components";
import display from "@src/presentation/design-tokens/display";

export const CountdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6.25rem;

  @media (max-width: ${display.md}) {
    display: block;
  }
`;

export const SectionTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  margin-bottom: 4rem;
  text-align: center;

  & > * {
    max-width: 40rem;
  }
`;

export const Wrapper = styled.div``;
