import styled from "styled-components";
import color from "@src/presentation/design-tokens/color";
import display from "@src/presentation/design-tokens/display";
import fontWeight from "@src/presentation/design-tokens/font-weight";
import margin from "@src/presentation/design-tokens/margin";
import background from "@src/presentation/assets/images/banner-background.svg";

interface ImageWrapperProps {
  imageUrl: string;
}

export const ContentWrapper = styled.div`
  background-image: url(${background});
  background-position: bottom center;
  background-size: cover;
  display: flex;
  height: 100%;
`;

export const Description = styled.div`
  color: ${color.neutral.light};
  font-size: 1.2rem;
  margin-bottom: 3.2rem;

  @media (max-width: ${display.md}) {
    display: -webkit-box;
    line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;

    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`;

export const DescriptionWrapper = styled.div`
  align-items: start;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: end;
  padding: 0 0 6rem 6rem;

  @media (max-width: ${display.md}) {
    padding: 0 2rem 2rem 2rem;
  }

  @media (min-width: ${display.lg}) {
    padding-bottom: 4rem;
  }
`;

export const ImageWrapper = styled.div<ImageWrapperProps>`
  background-image: url(${(props) => props.imageUrl});
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 2.5rem;
  font-weight: ${fontWeight.bold};
  margin-bottom: ${margin.m5};
  text-transform: uppercase;
`;

export const VerticalTitle = styled.div`
  color: transparent;
  font-size: 4rem;
  font-weight: ${fontWeight.bold};
  opacity: 0.15;
  text-transform: uppercase;
  text-stroke: 2px ${color.neutral.medium};
  transform: rotate(-90deg);
  white-space: nowrap;

  -webkit-text-stroke: 2px ${color.neutral.medium};
`;

export const VerticalTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 6rem;
`;

export const Wrapper = styled.div`
  background-color: ${color.primary.medium};
  color: ${color.neutral.medium};
  height: 40rem;
  margin-bottom: 3.2rem;

  & > * {
    height: 100%;
  }

  @media (max-width: ${display.md}) {
    height: 30rem;
  }
`;
