import faker from "@faker-js/faker";
import {
  createContext,
  useState,
  Dispatch,
  ReactElement,
  useEffect,
} from "react";
import { Creator } from "@src/core/models/creator";
import { mockCreator } from "@src/core/mocks/models/creator";
import profileImage from "@src/presentation/assets/images/sample-drop/Goose.png";

export interface CreatorContextProps {
  creator?: Creator;
}

interface CreatorProviderProps {
  children: ReactElement;
}

export const CreatorContext = createContext<CreatorContextProps>({});

export const CreatorProvider = ({
  children,
}: CreatorProviderProps): JSX.Element => {
  const [creator, setCreator] = useState<Creator>();

  useEffect(() => findCreator(setCreator), []);

  return (
    <CreatorContext.Provider value={{ creator }}>
      {children}
    </CreatorContext.Provider>
  );
};

const findCreator = (setCreator: Dispatch<Creator>): void => {
  // TODO: uncomment the following block as soon as we have a creator's endpoint
  // HttpClient.get<Creator>(endpoints.creatorFind(), (response) => {
  //   setCreator(response);
  // });
  setCreator(
    mockCreator({
      bio: "This summer Goose and James Flames will commemorate their summer tour with animated digital art. Follow Goose’s social media channels and sign up for updates to learn more about what else they have planned this summer. More information about the project can be found HERE.",
      hashId: faker.datatype.uuid(),
      id: faker.datatype.uuid(),
      name: "Goose x James Flames",
      profileImageUrl: profileImage,
      role: "Artist",
      socialMedia: [
        { type: "facebook", url: "https://facebook.com/goosetheband" },
        { type: "instagram", url: "https://www.instagram.com/goosetheband/" },
        { type: "twitter", url: "https://twitter.com/goosetheband" },
        { type: "website", url: "https://www.goosetheband.com/" },
      ],
    })
  );
};
