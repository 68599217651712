import { Col, Row } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaPinterest,
  FaTwitter,
  FaHome,
} from "react-icons/fa";
import { v4 as uuid } from "uuid";
import { Creator, CreatorSocialMedia } from "@src/core/models/creator";
import TitleSection from "@src/presentation/components/shared/title/section";
import CreatorCard from "@src/presentation/components/creator/card";
import * as Styles from "./styles";

interface Props {
  creator: Creator;
}

const CreatorProfile = ({ creator }: Props): JSX.Element => {
  return (
    <Styles.Wrapper>
      <Row>
        <Col xs={{ order: 2 }} lg={{ order: 1, span: 5 }} xl={4}>
          <CreatorCard creator={creator} />
        </Col>
        <Col xs={{ order: 1 }} lg={{ order: 2, span: 7 }} xl={8}>
          <Styles.ContentWrapper>
            <TitleSection text="Know the author" />
            <Styles.Bio>{creator.bio}</Styles.Bio>
            <Styles.SocialMediaWrapper>
              {renderSocialMedia(creator)}
            </Styles.SocialMediaWrapper>
          </Styles.ContentWrapper>
        </Col>
      </Row>
    </Styles.Wrapper>
  );
};

const renderSocialMedia = (creator: Creator): JSX.Element => {
  return (
    <Styles.SocialMediaWrapper>
      <Styles.SocialMediaLabel>Follow social:</Styles.SocialMediaLabel>
      {creator.socialMedia.map((socialMedia: CreatorSocialMedia) =>
        renderSocialMediaButton(socialMedia)
      )}
    </Styles.SocialMediaWrapper>
  );
};

const renderSocialMediaButton = (
  socialMedia: CreatorSocialMedia
): JSX.Element => {
  return (
    <Styles.SocialMediaButton
      target="_blank"
      href={socialMedia.url}
      key={uuid()}
    >
      {socialMediaIconsMap[socialMedia.type]}
    </Styles.SocialMediaButton>
  );
};

const socialMediaIconsMap = {
  facebook: <FaFacebook />,
  instagram: <FaInstagram />,
  pinterest: <FaPinterest />,
  twitter: <FaTwitter />,
  website: <FaHome />,
};

export default CreatorProfile;
