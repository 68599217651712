import styled from "styled-components";
import padding from "@src/presentation/design-tokens/padding";
import margin from "@src/presentation/design-tokens/margin";

export const Wrapper = styled.div`
  form {
    margin-top: ${margin.m3};
  }

  .btn {
    align-items: center;
    display: flex;
    font-size: 1.1rem;
    justify-content: center;
    margin-bottom: ${margin.m3};
    padding: ${padding.p3};
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
`;
