import { rgba } from "polished";
import styled from "styled-components";
import color from "@src/presentation/design-tokens/color";
import display from "@src/presentation/design-tokens/display";
import margin from "@src/presentation/design-tokens/margin";
import padding from "@src/presentation/design-tokens/padding";

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;

  @media (max-width: ${display.md}) {
    flex-flow: column;
    margin-bottom: ${margin.m4};
  }
`;

export const Link = styled.a`
  color: ${color.neutral.medium};
  text-decoration: none;
  margin: 0 ${margin.m2};

  @media (max-width: ${display.sm}) {
    margin-bottom: ${margin.m2};
  }
`;

export const LinksWrapper = styled.div`
  display: flex;

  @media (max-width: ${display.sm}) {
    flex-flow: column;
    text-align: center;
  }
`;

export const Logo = styled.img`
  height: 1.5rem;

  @media (max-width: ${display.md}) {
    height: 2rem;
  }
`;

export const LogoWrapper = styled.div`
  margin-right: ${margin.m4};

  @media (max-width: ${display.md}) {
    margin-bottom: ${margin.m4};
    margin-right: 0;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
`;

export const SocialMediaLink = styled.a`
  color: ${color.neutral.light};
  margin-left: ${margin.m4};

  svg {
    font-size: 1.5rem;
  }

  &:first-child {
    margin-left: 0;
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${color.primary.medium};
  border-top: 1px solid ${rgba(color.neutral.medium, 0.1)};
  bottom: 0;
  color: ${color.neutral.light};
  display: flex;
  left: 0;
  min-height: 4rem;
  justify-content: space-between;
  padding: ${padding.p4} 6rem;
  position: absolute;
  right: 0;

  @media (max-width: ${display.md}) {
    flex-flow: column;
  }
`;
