import { useEffect } from "react";
import logo from "@src/presentation/assets/images/logo.png";
import blp from "@src/presentation/assets/images/blp.png";
import goosebanner from "@src/presentation/assets/images/image1.png";
import goose1Preview from "@src/presentation/assets/images/Goose-NFT-Preview.gif";
import goose2Preview from "@src/presentation/assets/images/Goose2Preview.jpeg";
import EasyTimer from "easytimer.js";
import * as Styles from "./styles";

const RootPage = (): JSX.Element => {
  const images = [goose1Preview, goose2Preview];
  const isLive = (startDate: Date) => {
    return new Date(startDate).valueOf() <= new Date().valueOf();
  };

  let getMaticPrice = "";
  let grabListJsonData = "";
  let isShow = false;

  function isEqual(start: Date, end: Date) {
    return end.valueOf() === start.valueOf();
  }

  const setCountDown = (
    startDate: Date,
    index: number
  ): boolean | undefined => {
    const tempStartDate = new Date(startDate);
    // console.log(tempStartDate);
    const getCurrentDate = () => new Date();
    if (tempStartDate <= getCurrentDate()) {
      return true;
    }
    const gribTimer = new EasyTimer();
    const gribCountdownContainer = document.getElementById(
      `grabListCountDown${index > -1 ? index : "Top"}`
    );
    const currentDate = getCurrentDate();

    const distance = tempStartDate.valueOf() - currentDate.valueOf();

    const daysLeft = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutesLeft = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const secondsLeft = Math.floor((distance % (1000 * 60)) / 1000);

    if (!gribCountdownContainer) return undefined;

    // debugger;

    gribTimer.start({
      countdown: true,
      startValues: {
        days: daysLeft,
        seconds: secondsLeft,
        minutes: minutesLeft,
        hours: hoursLeft,
      },
    });

    const displayedValues: any = [
      daysLeft ? "days" : undefined,
      "hours",
      "minutes",
      "seconds",
    ].filter(Boolean);

    gribCountdownContainer.innerHTML = gribTimer
      .getTimeValues()
      .toString(displayedValues)
      .replaceAll(":", " : ");

    gribTimer.addEventListener("secondsUpdated", (_e) => {
      gribCountdownContainer.innerHTML = gribTimer
        .getTimeValues()
        .toString(displayedValues)
        .replaceAll(":", " : ");
    });

    gribTimer.addEventListener("targetAchieved", (_e) => {
      function delay(time: number) {
        return new Promise((resolve) => setTimeout(resolve, time));
      }
      delay(1000).then(() => grabOffersShow());
    });

    return undefined;
  };

  function grabOffersShow() {
    if (isShow) {
      // console.log("test");
      const maticUsd: any = getMaticPrice;
      const json: any = grabListJsonData;
      console.log(json);
      console.log(json.body.data.length);
      const getDate = (date: Date) => {
        const today = new Date(date);

        return new Date(
          `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
        );
      };

      const grabListElement = document.getElementById("grabList");
      const topGrabElement = document.getElementById("topGrab");

      if (!grabListElement) return;
      if (!topGrabElement) return;

      const usdPrice = "";
      let grabList = "";
      let isBlur = "";
      let showCountdown = "";
      let isShowTopGrab = false;
      let showTopGrab = "";
      let tempDate: Date = new Date();
      let finalTopGridDate: Date = tempDate;

      const dates = [];

      for (let i = 0; i < json.body.data.length; i++) {
        if (!isLive(json.body.data[i].attributes.startsAt)) {
          dates.push(new Date(json.body.data[i].attributes.startsAt));
        }
      }

      const showTopGrideDate = new Date(
        Math.min.apply(
          null,
          dates.map((item) => item.valueOf())
        )
      );

      for (let i = 0; i < json.body.data.length; i++) {
        tempDate = new Date(json.body.data[i].attributes.startsAt);

        if (!isLive(json.body.data[i].attributes.startsAt)) {
          isBlur = "blur-pro";
          showCountdown = "showCountdown";

          if (isEqual(showTopGrideDate, tempDate)) {
            if (!isShowTopGrab) {
              finalTopGridDate = tempDate;
              showTopGrab =
                `<div class="box m-auto"><a href="#home"><img src="${goosebanner}" alt="image not found"></a> </div>` +
                `<div class="hero-title text-center"><h2>Goose x James Flames</h2>` +
                `<h5>Dripfield 2022 Digital Art Series</h5>` +
                ` <p>Commemorate Goose's Dripfield 2022 Tour with animated digital art from James Flames.</p>` +
                `<div class="time-area"><div><div class="countdown" id="grabListCountDownTop" data-show-on-upcoming-drop></div></div></div><hr></div>`;
              topGrabElement.innerHTML = showTopGrab;
              isShowTopGrab = true;
            }
          }
        } else {
          isBlur = "";
          showCountdown = "";

          if (isEqual(getDate(new Date()), getDate(tempDate))) {
            showTopGrab =
              `<div class="box m-auto"><a href="#home"><img src="${goosebanner}" alt="image not found"></a> </div>` +
              `<div class="hero-title text-center"><h2>Goose x James Flames</h2>` +
              `<h5>Dripfield 2022 Digital Art Series</h5>` +
              ` <p>Commemorate Goose's Dripfield 2022 Tour with animated digital art from James Flames.</p>` +
              `<a href="${process.env.REACT_APP_DOMAIN}/offers/${process.env.REACT_APP_MAIN_OFFER_ID}" class="mint-n">MINT NOW</a>` +
              `<hr></div>`;
            topGrabElement.innerHTML = showTopGrab;
            isShowTopGrab = true;
          }
        }

        const offerImage = images.find(
          (item) =>
            item.indexOf(json.body.data[i].attributes.previewUrlSmall) > -1
        );
        let mintButtonText = "Mint Now";
        if (json.body.data[i].attributes.availableCount === "0") {
          mintButtonText = "Sold Out!";
        }
        grabList =
          `${grabList}<div class="col-lg-4 col-md-6 ${showCountdown}"><div class="girbBox ${isBlur}" data-grab-box >` +
          `<a href="${process.env.REACT_APP_DOMAIN}/offers/${json.body.data[i].attributes.offerId}" class="innerbox1">` +
          `<img src="${offerImage}" alt="">` +
          `</a>` +
          `<div class="title-box"><table><tr class="firstRow">` +
          `<th class="f-text">${json.body.data[i].attributes.name}</th>` +
          `<td class="n-text" rowspan="2">${
            json.body.data[i].attributes.totalItems -
            json.body.data[i].attributes.availableCount
          }/${json.body.data[i].attributes.totalItems}</td>` +
          `</tr></table><hr><table><tr><td class="price" colspan="2">Price</td></tr><tr><td class="td-text" colspan="2">${json.body.data[i].attributes.price} Matic  &nbsp; <span class="n-text">` +
          `<span class="usd">
          ${foramtUsd(
            json.body.data[i].attributes.price * maticUsd.wmatic.usd
          )}</span>` +
          `</span></td></tr></table>` +
          `<a href="${process.env.REACT_APP_DOMAIN}/offers/${json.body.data[i].attributes.offerId}"	class="bid" style="text-decoration: none;">` +
          `<span> ${mintButtonText} </span></a></div></div><div class="countDownOverlay" ><div class="droppingIn">DROPPING IN</div><div id="grabListCountDown${i}"></div></div></div>`;

        grabListElement.innerHTML = grabList;
      }

      grabListElement.innerHTML = grabList;

      for (let i = 0; i < json.body.data.length; i++) {
        if (!isShowTopGrab) {
          showTopGrab =
            `<div class="box m-auto"><a href="#home"><img src="${goosebanner}" alt="image not found"></a> </div>` +
            `<div class="hero-title text-center"><h2>Goose x James Flames</h2>` +
            `<h5>Dripfield 2022 Digital Art Series</h5>` +
            ` <p>Commemorate Goose's Dripfield 2022 Tour with animated digital art from James Flames.</p>` +
            `<a href="${process.env.REACT_APP_DOMAIN}/offers/${process.env.REACT_APP_MAIN_OFFER_ID}" class="mint-n">MINT NOW</a>` +
            `<hr></div>`;
          topGrabElement.innerHTML = showTopGrab;
          isShowTopGrab = true;
        }
      }

      isShowTopGrab = false;

      for (let i = 0; i < json.body.data.length; i++) {
        setCountDown(json.body.data[i].attributes.startsAt, i);
        if (
          isEqual(
            finalTopGridDate,
            new Date(json.body.data[i].attributes.startsAt)
          )
        ) {
          setCountDown(json.body.data[i].attributes.startsAt, -1);
          isShowTopGrab = true;
        }
      }
    }
  }

  const getMaticUsd = () => {
    fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=wmatic&vs_currencies=usd"
    )
      .then((response) => response.json())
      .then((json) => {
        getMaticPrice = json;
      })
      .catch((error) => console.error(error));
  };

  const grabOffers = () => {
    fetch(`${process.env.REACT_APP_OFFERS_API_URL}/`)
      .then((response) => response.json())
      .then((json) => {
        grabListJsonData = json;
        isShow = true;
        grabOffersShow();
      })
      .catch((error) => console.error(error));
  };

  function foramtUsd(val: number) {
    const formattedPrice = new Intl.NumberFormat("en-US", {
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "currency",
    }).format(val);
    return formattedPrice;
  }

  useEffect(() => {
    getMaticUsd();
    grabOffers();
  }, []);

  const toggleNav = (visible: boolean): void => {
    const element = document.getElementById("myNav");

    if (element) element.style.width = visible ? "100%" : "0%";
  };

  return (
    <Styles.Container>
      <div className="messagebar">
        Note: the optimal minting experience is supported on Google Chrome or
        Firefox using the MetaMask browser extension, or on mobile via the
        MetaMask in-app browser.
      </div>
      <header className="header-area" id="home">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-brand studio" href="/">
              <img src={logo} alt="Artist Logo" />
            </a>
            <div className="collapse navbar-collapse colps" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <a className="nav-link text-black" href="#about">
                    ABOUT
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-black" href="#faqs">
                    FAQs
                  </a>
                </li>
              </ul>
            </div>
            <div id="myNav" className="overlay">
              <button
                type="button"
                className="closebtn"
                onClick={() => toggleNav(false)}
              >
                &times;
              </button>
              <div className="overlay-content">
                <a href="#faqs" onClick={() => toggleNav(false)}>
                  FAQS
                </a>
                <a href="#about" onClick={() => toggleNav(false)}>
                  ABOUT
                </a>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <section className="hero-area">
        <div className="container" id="topGrab">
          <div className="box m-auto">
            <a href="#home">
              <img src={goose2Preview} alt="Artist Preview" />
            </a>
          </div>
          <div className="hero-title text-center">
            <h2>Goose x James Flames</h2>
            <h5>Dripfield 2022 Digital Art Series</h5>
            <p>
              Commemorate Goose&apos;s Dripfield 2022 Tour with animated digital
              art from James Flames.
            </p>
            <a
              href={`${process.env.REACT_APP_DOMAIN}/offers/${process.env.REACT_APP_MAIN_OFFER_ID}`}
              className="mint-n"
            >
              MINT NOW
            </a>
            <hr />
          </div>
        </div>
      </section>
      <section className="collection-area">
        <div className="container">
          <div className="collection-title">
            <h2>Shop</h2>
            <p>
              Goose x James Flames DRIP is a series of commemorative digital art
              pieces being released alongside Goose&apos;s 2022 Tour. DRIP NFTs
              commemorate the band&apos;s performances at Red Rocks, Radio City
              Music Hall, and more!
            </p>
          </div>
          <div className="row" id="grabList" />
        </div>
      </section>
      <section className="about-area" id="about">
        <div className="container">
          <div className="about-title-2">
            <h2>About</h2>
            <p className="content1">
              Goose and James Flames will commemorate the band&apos;s summer
              tour with animated digital art. Follow Goose&apos;s social media
              channels and sign up for updates to learn more about what else
              they have planned this summer. More information about the project
              can be found &nbsp;
              <a href="http://goosetheband.com/nft" className="regularlink">
                HERE
              </a>
              .
            </p>
          </div>
          <div className="about-title">
            <a
              href="https://twitter.com/goosetheband"
              target="_blank"
              rel="noreferrer"
              className="aboutlink"
            >
              Twitter
            </a>
            <a
              href="https://www.instagram.com/goosetheband/"
              target="_blank"
              rel="noreferrer"
              className="aboutlink"
            >
              Instagram
            </a>
            <a
              href="https://www.facebook.com/gooseband"
              target="_blank"
              rel="noreferrer"
              className="aboutlink"
            >
              Facebook
            </a>
            <a
              href="https://www.goosetheband.com"
              target="_blank"
              rel="noreferrer"
              className="aboutlink"
            >
              Website
            </a>
          </div>
        </div>
      </section>
      <section className="asked-area" id="faqs">
        <div className="container">
          <div className="ques">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="sw-accordion" id="sw-accordionExample">
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingOne">
                <button
                  type="button"
                  data-bs-toggle="collapse"
                  className="human sw-accordion-button"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  How many NFTs are there and how much will they cost?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="sw-accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  All NFTs in this series will be available in Polygon (Matic)
                  for approximately $49.99 USD at the time of release.
                </div>
              </div>
            </div>
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingTwo">
                <button
                  type="button"
                  data-bs-toggle="collapse"
                  className="human sw-accordion-button collapsed"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Will the NFTs be sold on open marketplaces?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="sw-accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  The initial sale will take place solely on this site. Please
                  confirm you&apos;re on the correct site before connecting your
                  wallet. If the drop is sold out or you want a specific edition
                  number you&apos;ll be able to purchase on the secondary market
                  of your choice that supports trading of Polygon-based NFTs,
                  such as the&nbsp;
                  <a href="https://www.nft.org/">
                    <strong>NFT Protocol DEX.</strong>
                  </a>
                </div>
              </div>
            </div>
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingThree">
                <button
                  type="button"
                  data-bs-toggle="collapse"
                  className="human sw-accordion-button collapsed"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How do I purchase an NFT?
                </button>
              </h2>
              <div
                id="collapseThree"
                className="sw-accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  <ul>
                    <li>
                      {" "}
                      Download and install the MetaMask Chrome Extension or
                      mobile app*
                    </li>
                    <li> Add the Polygon (MATIC) Network to your MetaMask</li>
                    <li>
                      {" "}
                      Fund your wallet with enough MATIC to cover the cost of
                      the NFT plus fees
                    </li>
                    <li>
                      {" "}
                      Connect to our mint site with your MetaMask wallet (you
                      will be prompted to switch to the Polygon Network if not
                      already on it){" "}
                    </li>
                    <li>
                      {" "}
                      Click Mint Now to complete your transaction and mint your
                      NFT
                    </li>
                  </ul>
                  <br />
                  *NOTE: The optimal checkout experience is supported on desktop
                  using Google Chrome or Firefox with the MetaMask wallet
                  browser extension installed. If you would like to purchase on
                  mobile, please download the MetaMask wallet mobile app,
                  connect your wallet, and navigate to this site via the in-app
                  MetaMask browser to complete your purchase.
                </div>
              </div>
            </div>
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingFour">
                <button
                  className="human sw-accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  How to download and install MetaMask on desktop (recommended)
                </button>
              </h2>
              <div
                id="collapseFour"
                className="sw-accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  To set up a MetaMask wallet follow the steps below:
                  <br />
                  <br />
                  <ul>
                    <li>
                      Visit MetaMask.io and select ‘Download&apos;. Select how
                      you want to install the program, on Chrome, iOS, or
                      Android (Chrome browser extension is recommended)
                      extension is recommended)
                    </li>
                    <li>
                      Once you have installed MetaMask, set your password.
                      Choose a strong password and protect it
                    </li>
                    <li>
                      IMPORTANT: You will then see your 12-word seed phrase.
                      This is a secret recovery key that can be used to restore
                      your account if you get locked out. Write this seed phrase
                      down on paper and keep it safe! If you lose this you are
                      at risk of permanently losing access to your wallet and
                      the contents stored within it. MetaMask is a third-party
                      application and Blockparty is not responsible for nor
                      capable of recovering any NFTs or funds custodied in a
                      MetaMask wallet.
                    </li>
                    <li>
                      Once you have recorded your seed phrase you will be ready
                      to use MetaMask!
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingFive">
                <button
                  className="human sw-accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  How to download and install MetaMask on mobile
                </button>
              </h2>
              <div
                id="collapseFive"
                className="sw-accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  To set up a MetaMask wallet follow the steps below:*. <br />
                  <br />
                  <ul>
                    <li>
                      Visit the Google Playstore on your Android device or the
                      App Store on your iOS device.
                    </li>
                    <li>Search for MetaMask and download the mobile app.</li>
                    <li>
                      Once you have installed MetaMask, set your password.
                      Choose a strong password and protect it.
                    </li>
                    <li>
                      IMPORTANT: You will then see your 12-word seed phrase.
                      This is a secret recovery key that can be used to restore
                      your account if you get locked out. Write this seed phrase
                      down on paper and keep it safe! If you lose this you are
                      at risk of permanently losing access to your wallet and
                      the contents stored within it. MetaMask is a third-party
                      application and Blockparty is not responsible for nor
                      capable of recovering any NFTs or funds custodied in a
                      MetaMask wallet.
                    </li>
                    <li>
                      Once you have recorded your seed phrase you will be ready
                      to use MetaMask!
                    </li>
                  </ul>
                  <br />
                  *NOTE: If you want to use an existing MetaMask wallet on your
                  mobile app, select ‘Import Wallet’ and use the 12-word seed
                  phrase from your original wallet to add it in the mobile app.
                </div>
              </div>
            </div>
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingSix">
                <button
                  className="human sw-accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How to add the Polygon Network to your MetaMask (required to
                  fund your wallet with MATIC)
                </button>
              </h2>
              <div
                id="collapseSix"
                className="sw-accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  Before you can add funds to your MetaMask wallet, you will
                  need to change the network MetaMask is connected to from
                  Ethereum Mainnet to Polygon (MATIC) Mainnet. <br />
                  <br />
                  <ul>
                    <li>
                      Navigate to{" "}
                      <strong>
                        <a
                          href="https://polygonscan.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Polygonscan.com
                        </a>
                      </strong>
                    </li>
                    <li>
                      Scroll down to the bottom of the page and click on the
                      “Add Polygon Network” button on the bottom righthand side.
                    </li>
                    <li>
                      Once you click the button you will see a Metamask
                      Notification, now click on Approve. You will be directly
                      switched to Polygon (MATIC) Mainnet now in the network
                      dropdown list. You can now close the dialog.
                    </li>
                  </ul>
                  <br />
                  NOTE: when on our mint site (on desktop and mobile), you will
                  automatically prompted to Add the Polygon Network and/or
                  Switch to the Polygon Network if you have not already done so.
                </div>
              </div>
            </div>
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingSeven">
                <button
                  className="human sw-accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  How to fund your MetaMask with MATIC
                </button>
              </h2>
              <div
                id="collapseSeven"
                className="sw-accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  Next, you&apos;ll need to add funds so you can make your
                  purchase. If you already have crypto in your wallet, you can
                  convert it to MATIC using the Swap functionality in your
                  MetaMask wallet.
                  <br />
                  <br />
                  If you do not have crypto in your wallet, MoonPay enables
                  users in most regions to buy MATIC directly in your MetaMask
                  wallet with a credit or debit card following the steps below.
                  NY residents skip to the following section for more info on
                  how to purchase MATIC.
                  <ol>
                    <li>
                      Open your MetaMask wallet and check that you are connected
                      to MATIC Mainnet Network
                    </li>
                    <li>Select the ‘Buy&apos; button</li>
                    <li>
                      Scroll down to Moonpay and select ‘Continue to
                      MoonPay&apos;
                    </li>
                    <li>
                      Choose the ‘Currency&apos; and ‘Amount&apos;. The amount
                      of MATIC you buy will depend on the price of the NFT. Make
                      sure to add a little bit more to cover the gas fees
                      associated with processing transactions on the blockchain.
                    </li>
                    <li>
                      You will be prompted to sign up and create an account with
                      MoonPay if you have not already done so. Follow the
                      prompts to complete the standard KYC onboarding process.
                      This can take a few minutes so we recommend funding your
                      wallet in advance of the drop if you plan to use MoonPay
                      to add funds.
                    </li>
                    <li>
                      Now enter your wallet address. You can find this under
                      ‘Account Details&apos; on your MetaMask profile. Copy to
                      clipboard and paste it in the field.
                    </li>

                    <li>
                      Once you confirm, MoonPay will automatically connect your
                      MetaMask account address.
                    </li>
                    <li>
                      Note: If you are using the MetaMask mobile app, you also
                      have the option to pay using debit/credit card, Apple Pay,
                      or instant bank transfer.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingEight">
                <button
                  className="human sw-accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  How to purchase MATIC as a NY resident (or another region not
                  supported by MoonPay)
                </button>
              </h2>
              <div
                id="collapseEight"
                className="sw-accordion-collapse collapse"
                aria-labelledby="headingEight"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  Some states in the US and certain countries do not yet support
                  directly purchasing cryptocurrencies due to regulatory
                  licensing. If this impacts you, you can purchase crypto on a
                  centralized exchange such as Coinbase, and then send it to
                  your MetaMask following the steps below: <br />
                  <br />
                  <ul>
                    <li>Create a Coinbase wallet via coinbase.com</li>

                    <li>Complete the KYC process as prompted to do so</li>
                    <li>Connect your bank account and/or credit card</li>

                    <li>Purchase MATIC (Polygon)</li>

                    <li>In MetaMask, copy your wallet address</li>

                    <li>
                      In Coinbase, navigate to the Send/Receive Payment portion
                      of the application
                    </li>

                    <li>
                      Select the currency you want to send from Coinbase to
                      MetaMask (MATIC), the amount you want to send, and paste
                      your MetaMask wallet address as the recipient
                    </li>

                    <li>
                      We suggest doing a test with a small amount of funds first
                      to make sure you have the correct recipient address, since
                      misplaced funds cannot be recovered. Sending funds is not
                      always processed instantly, so we also recommend doing
                      this process well in advance of the drop going live
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingNine">
                <button
                  className="human sw-accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  How do I complete my NFT purchase?
                </button>
              </h2>
              <div
                id="collapseNine"
                className="sw-accordion-collapse collapse"
                aria-labelledby="headingNine"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  To purchase a piece from the collection, simply hit the ‘Mint
                  Now&apos; button and complete the following steps:
                  <br />
                  <br />
                  <ul className="m-0">
                    <li>
                      Connect your MetaMask wallet to the site and switch to the
                      Polygon Network when prompted to do so
                    </li>

                    <li>
                      Make sure you have enough MATIC in your wallet to cover
                      the cost of the NFT plus a minimal gas fee
                    </li>

                    <li>Sign a transaction to confirm your NFT purchase</li>
                  </ul>
                  <br />
                  Make sure to set a reminder for when the drop goes live.
                </div>
              </div>
            </div>
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingTen">
                <button
                  className="human sw-accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  Where can I view my NFT?
                </button>
              </h2>
              <div
                id="collapseTen"
                className="sw-accordion-collapse collapse"
                aria-labelledby="headingTen"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  Now that you&apos;ve created your MetaMask wallet and have
                  your own address, you can use it to connect to other sites
                  like{" "}
                  <a
                    target="_blank"
                    href="https://app.nft.org/"
                    rel="noreferrer"
                  >
                    NFT.org
                  </a>{" "}
                  and OpenSea.io, where you can view your collection. If you do
                  not immediately see your NFT, please check the Hidden tab in
                  your account on OpenSea.
                </div>
              </div>
            </div>
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingEleven">
                <button
                  className="human sw-accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven"
                  aria-expanded="false"
                  aria-controls="collapseEleven"
                >
                  What blockchain are these NFTs minted on?
                </button>
              </h2>
              <div
                id="collapseEleven"
                className="sw-accordion-collapse collapse"
                aria-labelledby="headingEleven"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  All of the NFTs from this collection are minted on Polygon.
                </div>
              </div>
            </div>
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingTwelve">
                <button
                  className="human sw-accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwelve"
                  aria-expanded="false"
                  aria-controls="collapseTwelve"
                >
                  Can I buy more than one NFT?
                </button>
              </h2>
              <div
                id="collapseTwelve"
                className="sw-accordion-collapse collapse"
                aria-labelledby="headingTwelve"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  Of course! You can purchase as many as you would like.
                </div>
              </div>
            </div>
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingThirteen">
                <button
                  className="human sw-accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThirteen"
                  aria-expanded="false"
                  aria-controls="collapseThirteen"
                >
                  How do I prepare for the drop?
                </button>
              </h2>
              <div
                id="collapseThirteen"
                className="sw-accordion-collapse collapse"
                aria-labelledby="headingThirteen"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  Set a reminder for the day the collection goes live. Make sure
                  you&apos;ve installed and set up a MetaMask wallet and
                  purchased or sent enough MATIC to cover the cost of the
                  piece(s) you want to purchase, as well as any gas fees
                  required to transact, and you should be good to go!
                </div>
              </div>
            </div>
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingFourteen">
                <button
                  className="human sw-accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFourteen"
                  aria-expanded="false"
                  aria-controls="collapseFourteen"
                >
                  Can I resell my NFT?
                </button>
              </h2>
              <div
                id="collapseFourteen"
                className="sw-accordion-collapse collapse"
                aria-labelledby="headingFourteen"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  Of course! Your NFT will be available for resale on OpenSea.io
                  or NFT.org shortly after your transaction is processed.
                </div>
              </div>
            </div>
            <div className="sw-accordion-item bdr">
              <h2 className="sw-accordion-header" id="headingFifteen">
                <button
                  className="human sw-accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFifteen"
                  aria-expanded="false"
                  aria-controls="collapseFifteen"
                >
                  Read through the FAQ and still need help?
                </button>
              </h2>
              <div
                id="collapseFifteen"
                className="sw-accordion-collapse collapse"
                aria-labelledby="headingFifteen"
                data-bs-parent="#sw-accordionExample"
              >
                <div className="sw-accordion-body acr">
                  If you have read through the FAQ and still need assistance,
                  please reach out to{" "}
                  <a
                    href="https://share.hsforms.com/1dAyUO24nTiC9E28ri0tnfA5jqzu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blockparty Support
                  </a>{" "}
                  so we can help you out!
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-area not-mobile">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-2 ">
              <div className="privacy">
                <p className="m-0">
                  <a
                    href="https://blockparty.co/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy&nbsp;
                    <span>•</span>
                  </a>{" "}
                  <a
                    href="https://blockparty.co/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="power">
                <a
                  href="https://blockparty.co/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>POWERED BY</span>
                  <img src={blp} alt="Blockparty Powered By" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="text-end first-tg">
                <a
                  className="market"
                  href="https://twitter.com/blockparty"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
                <a
                  className="market mrc"
                  href="https://blockparty.co/marketplace"
                  target="_blank"
                  rel="noreferrer"
                >
                  Marketplace
                </a>
              </div>
              <div className="text-end first-tg1">
                <a
                  className="market"
                  href="https://www.instagram.com/goblockparty/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
                <a
                  className="market mrc1"
                  href="ttps://discord.com/invite/qp9DqgrMCu"
                  target="_blank"
                  rel="noreferrer"
                >
                  Discord
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <footer className="footer-area mobile">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="power">
                <a
                  href="https://blockparty.co/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>POWERED BY</span>
                  <img src={blp} alt="Blockparty Powered by" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="text-end first-tg">
                <a
                  className="market"
                  href="https://twitter.com/blockparty"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitter
                </a>
                <a
                  className="market mrc"
                  href="https://blockparty.co/marketplace"
                  target="_blank"
                  rel="noreferrer"
                >
                  Marketplace
                </a>
                <a
                  className="market"
                  href="https://www.instagram.com/goblockparty/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
                <a
                  className="market mrc1"
                  href="ttps://discord.com/invite/qp9DqgrMCu"
                  target="_blank"
                  rel="noreferrer"
                >
                  Discord
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-2">
              <div className="privacy">
                <p className="m-0">
                  <a
                    href="https://blockparty.co/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy
                    <span>•</span>
                  </a>{" "}
                  <a
                    href="https://blockparty.co/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <Link to={`/drops/${uuid()}`}>onClick here to see a sample drop</Link> */}
    </Styles.Container>
  );
};

export default RootPage;
