import moment from "moment";
import { Dispatch, ReactElement, useContext, useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import endpoints from "@src/core/api/endpoints";
import { HttpClient } from "@src/core/api/http-client";
import { Address } from "@src/core/models/address";
import { Customer } from "@src/core/models/customer";
import { Offer } from "@src/core/models/offer";
import { Order, OrderStatus } from "@src/core/models/order";
import OrderCard from "@src/presentation/components/orders/card";
import PageLayout from "@src/presentation/components/shared/page-layout";
import { Wallet } from "@src/core/models/wallet";
import { MetaMaskWalletContext } from "@src/core/contexts/meta-mask-wallet";
import { BloctoWalletContext } from "@src/core/contexts/blocto-wallet";
import * as Styles from "./styles";

const AccountShowPage = (): JSX.Element => {
  const [customer, setCustomer] = useState<Customer>();
  const [address, setAddress] = useState<Address>();
  const [orders, setOrders] = useState<Order[]>();
  const [offers, setOffers] = useState<Offer[]>();

  const { bloctoWallet } = useContext(BloctoWalletContext);
  const { metaMaskWallet } = useContext(MetaMaskWalletContext);
  const currentWallet = bloctoWallet || metaMaskWallet;

  useEffect(() => findCustomer(setCustomer), []);
  useEffect(() => customer && findAddress(customer.id, setAddress), [customer]);
  useEffect(
    () => currentWallet && findOrders(currentWallet, setOrders),
    [currentWallet]
  );
  useEffect(() => findOffers(setOffers), []);

  return (
    <PageLayout>
      <Styles.Wrapper>
        <Container>
          <Tab.Container defaultActiveKey="nfts">
            <Row>
              <Col lg={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="nfts">My Purchases</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="orders">Pending orders</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="nfts">
                    {orders &&
                      offers &&
                      renderFinishedOrdersList(orders, offers)}
                  </Tab.Pane>
                  <Tab.Pane eventKey="orders">
                    {orders &&
                      offers &&
                      renderPendingOrdersList(orders, offers)}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Styles.Wrapper>
    </PageLayout>
  );
};

const findCustomer = (setCustomer: Dispatch<Customer>): void => {
  HttpClient.get<Customer>(endpoints.customerFind(), (response) => {
    setCustomer(response);
  });
};

const findAddress = (id: string, setAddress: Dispatch<Address>): void => {
  HttpClient.get<Address>(endpoints.addressFind(id), (response) => {
    setAddress(response);
  });
};

const findOrders = (
  currentWallet: Wallet,
  setOrders: Dispatch<Order[]>
): void => {
  HttpClient.list<Order>(endpoints.ordersList(currentWallet.id), (response) => {
    setOrders(response);
  });
};

const findOffers = (setOffers: Dispatch<Offer[]>): void => {
  HttpClient.list<Offer>(endpoints.offersList(), (response) => {
    setOffers(response);
  });
};

const renderFinishedOrdersList = (
  orders: Order[],
  offers: Offer[]
): ReactElement => {
  return renderOrdersList(orders, offers, OrderStatus.Finished);
};

const renderPendingOrdersList = (
  orders: Order[],
  offers: Offer[]
): ReactElement => {
  return renderOrdersList(orders, offers, OrderStatus.AwatingConfirmation);
};

const renderOrdersList = (
  orders: Order[],
  offers: Offer[],
  orderStatus: OrderStatus
): ReactElement => {
  return (
    <Row>
      {orders
        .filter((order) => {
          return order.status === orderStatus;
        })
        .sort((previous, next) => {
          return moment(next.updatedAt).isAfter(previous.updatedAt) ? 1 : -1;
        })
        .map((order) => {
          const orderOffer = offers.find(
            (offer: Offer) => offer.id === order.offerId
          );

          if (!orderOffer) return undefined;

          return (
            <Col xs={12} lg={6} key={uuid()}>
              <OrderCard offer={orderOffer} order={order} />
            </Col>
          );
        })
        .filter((order) => !!order)}
    </Row>
  );
};

export default AccountShowPage;
