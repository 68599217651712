import styled from "styled-components";
import border from "@src/presentation/design-tokens/border";
import color from "@src/presentation/design-tokens/color";
import display from "@src/presentation/design-tokens/display";
import fontWeight from "@src/presentation/design-tokens/font-weight";
import margin from "@src/presentation/design-tokens/margin";
import padding from "@src/presentation/design-tokens/padding";

export const Wrapper = styled.div`
  align-items: center;
  background: linear-gradient(
    152.97deg,
    rgba(156, 156, 156, 0.25) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(2.5rem);
  border-radius: ${border.radius.none};
  color: ${color.neutral.medium};
  cursor: pointer;
  display: flex;
  flex-flow: column;
  height: 15rem;
  justify-content: center;
  margin-right: ${margin.m5};
  padding: ${padding.p4};
  text-align: center;
  width: 20rem;

  &:last-child {
    margin-right: 0;
  }

  &:active,
  &:focus,
  &:hover {
    background: linear-gradient(
      152.97deg,
      rgba(156, 156, 156, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  @media (max-width: ${display.md}) {
    flex-flow: row;
    height: auto;
    margin-bottom: ${margin.m5};
    margin-right: ${margin.m0};
    text-align: left;
    width: 20rem;
  }
`;

export const Description = styled.div`
  font-weight: ${fontWeight.medium};
`;

export const Image = styled.img`
  width: 3rem;
`;

export const ImageWrapper = styled.div`
  align-items: center;
  background-color: ${color.neutral.medium};
  border-radius: 100%;
  display: flex;
  height: 5rem;
  justify-content: center;
  margin-bottom: ${margin.m2};
  width: 5rem;

  @media (max-width: ${display.md}) {
    margin-right: ${margin.m4};
    margin-bottom: ${margin.m0};
  }
`;

export const ProviderName = styled.div`
  font-weight: ${fontWeight.semibold};
  font-size: 1.25rem;
  margin-bottom: ${margin.m2};
`;
