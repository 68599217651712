import { Dispatch, ReactElement, useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import endpoints from "@src/core/api/endpoints";
import { HttpClient } from "@src/core/api/http-client";
import { CreatorContext } from "@src/core/contexts/creator";
import { DropContext } from "@src/core/contexts/drop";
import { Drop } from "@src/core/models/drop";
import { Offer } from "@src/core/models/offer";
import { scrollTo } from "@src/core/support/scroll";
import CreatorProfile from "@src/presentation/components/creator/profile";
import DropCountdown from "@src/presentation/components/drops/countdown";
import DropExpiredMessage from "@src/presentation/components/drops/expired-message";
import DropPromotionBanner from "@src/presentation/components/drops/promotion-banner";
import OfferCard from "@src/presentation/components/offers/card";
import HorizontalLine from "@src/presentation/components/shared/horizontal-line";
import PageLayout from "@src/presentation/components/shared/page-layout";
import TitleSection from "@src/presentation/components/shared/title/section";
import * as Styles from "./styles";

const DropShowPage = (): JSX.Element => {
  const { drop, dropIsExpired, dropIsActive } = useContext(DropContext);
  const { creator } = useContext(CreatorContext);
  const [offers, setOffers] = useState<Offer[]>();

  useEffect(() => drop && findOffers(drop, setOffers), [drop]);

  return (
    <PageLayout>
      <Styles.Wrapper>
        {drop && offers && (
          <Row>
            <DropPromotionBanner
              onClick={() => scrollTo(OFFERS_LIST_ID)}
              drop={drop}
              offers={offers}
            />
          </Row>
        )}
        <Container>
          {renderCountdown(drop, dropIsActive, dropIsExpired)}
          {renderSectionTitle()}
          {offers && renderOffersList(offers)}
          <HorizontalLine />
          {creator && <CreatorProfile creator={creator} />}
        </Container>
      </Styles.Wrapper>
    </PageLayout>
  );
};

const OFFERS_LIST_ID = uuid();

const renderCountdown = (
  drop?: Drop,
  dropIsActive?: boolean,
  dropIsExpired?: boolean
): ReactElement => {
  return (
    <Styles.CountdownWrapper>
      {drop && (
        <DropCountdown
          drop={drop}
          dropIsActive={dropIsActive}
          dropIsExpired={dropIsExpired}
        />
      )}
      {dropIsExpired && <DropExpiredMessage />}
    </Styles.CountdownWrapper>
  );
};

const renderSectionTitle = (): ReactElement => {
  const title = "Explore the collection";

  return (
    <Styles.SectionTitleWrapper id={OFFERS_LIST_ID}>
      <TitleSection text={title} />
      <p>
        Explore on the world&apos;s best &amp; largest NFT marketplace with our
        beautiful NFT products. We want to be a part of your smile, success and
        future growth.
      </p>
    </Styles.SectionTitleWrapper>
  );
};

const renderOffersList = (offers: Offer[]): ReactElement => {
  return (
    <Row>
      {offers
        .sort((offer, previousOffer) =>
          offer.name > previousOffer.name ? 1 : -1
        )
        .map((offer) => (
          <Col xs={12} md={6} lg={4} xl={3} key={uuid()}>
            <OfferCard offer={offer} />
          </Col>
        ))}
    </Row>
  );
};

const findOffers = (drop: Drop, setOffers: Dispatch<Offer[]>): void => {
  HttpClient.list<Offer>(endpoints.offersListByDrop(drop.id), (response) => {
    setOffers(response);
  });
};

export default DropShowPage;
