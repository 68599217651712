import toparw from "@src/presentation/assets/images/top-arw.png";
import styled from "styled-components";

export const Container = styled.div`
  .accordion-button:not(.collapsed)::after {
    background-image: url(${toparw});
    transform: rotate(0deg);
  }

  .accordion-button::after {
    background-image: url(${toparw});
    transform: rotate(-180deg);
  }
`;
