import { Col, Form, Row, Button, Card } from "react-bootstrap";
import TitleSubSection from "@src/presentation/components/shared/title/sub-section";

const OfferSoldOut = (): JSX.Element => {
  return (
    <Card>
      <Card.Body>
        <TitleSubSection text="Sold out" />
      </Card.Body>
    </Card>
  );
};

export default OfferSoldOut;
