import styled from "styled-components";
import padding from "@src/presentation/design-tokens/padding";

export const Image = styled.img`
  width: 100%;
  transition: 0.7s;
`;

export const OfferThumbnail = styled.div`
  align-items: center;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  margin: ${padding.p4};
  overflow: hidden;

  video {
    width: 100%;
  }

  &:hover {
    ${Image} {
      transform: scale(1.03);
      transition: 0.7s;
    }
  }
`;
