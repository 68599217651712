import { useParams } from "react-router-dom";
import CheckoutSuccess from "@src/presentation/components/checkout/success";
import PageLayout from "@src/presentation/components/shared/page-layout";

interface CheckoutSuccessPageParams {
  transactionHash: string;
}

const CheckoutSuccessPage = (): JSX.Element => {
  const { transactionHash } = useParams<CheckoutSuccessPageParams>();

  return (
    <PageLayout>
      <CheckoutSuccess transactionHash={transactionHash} />
    </PageLayout>
  );
};

export default CheckoutSuccessPage;
